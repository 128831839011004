import AxiosRequest from "../axios/AxiosRequest";
import axios from 'axios';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const actualizarProducto = async (id, producto) => {

    return new Promise( (resolve,eject) => {

      AxiosRequest.put(`/api/product/${id}`, producto)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        })
    });
}

export const updateProvider = async (id, provider) => {

    return new Promise( (resolve,eject) => {

      AxiosRequest.put(`/api/product/updateProvider/${id}`, provider)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        })
    });
}

export const registrarProducto = async (producto)=>{
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product", producto)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const saveMerge = async (producto)=>{
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/merge", producto)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const saveReceived = async (producto)=>{
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/received", producto)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const savePrice = async (producto)=>{
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/price", producto)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const savePaymentExtra = async (payment)=>{
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/payment", payment)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const saveIncrease = async (producto)=>{
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/increase", producto)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}


export const getProductos = (request) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/product?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}&category=${request.category}`).then( response =>{
            resolve(response);
        });
    })    
};

export const getProducto =  id => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/product/${id}`)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            resolve(error.response);
        });
    });
}

export const getProductoByAssociate =  id => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/product/associate/${id}`)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            resolve(error.response);
        });
    });
}

export const getDiscountByAssociate =  id => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/product/discount/${id}`)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            resolve(error.response);
        });
    });
}

export const getPaymentInvoce =  id => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/product/invocePayment/${id}`)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            resolve(error.response);
        });
    });
}

export const eliminarProducto = async (id) => {

  return new Promise( (resolve,eject) => {

    AxiosRequest.delete(`/api/product/${id}`)
      .then(response => {
          resolve(response);
      })
      .catch(error => {
          resolve(error.response);
      })
  });
}

export const deleteOrder = async (id) => {

    return new Promise( (resolve,eject) => {
  
      AxiosRequest.delete(`/api/product/order/${id}`)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        })
    });
  }

  export const deleteOrderInvoce = async (id) => {

    return new Promise( (resolve,eject) => {
  
      AxiosRequest.delete(`/api/product/orderSale/${id}`)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        })
    });
  }

  export const deleteOrderBuy = async (id) => {

    return new Promise( (resolve,eject) => {
  
      AxiosRequest.delete(`/api/product/deleteOrderBuy/${id}`)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        })
    });
  }


export const registrarCompra = async (sale)=>{   
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/sale", sale)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const saleProduct = async (sale)=>{   
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/saleProduct", sale)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const getSales = (request) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/product/sales?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}`).then( response =>{
            resolve(response);
        });
    })    
};

export const fetchProducts = () => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/product/cascara`).then( response =>{
            resolve(response);
        });
    })    
};

export const getSalesPay = (request) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/product/salesPay?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&search=${request.search}&group=${request.group}`).then( response =>{
            resolve(response);
        });
    })    
};

export const getSalesPayConfirm = (request) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/product/salesPayConfirm?code=${request.code}&dateFrom=${request.dateFrom}&dateTo=${request.dateTo}`).then( response =>{
            resolve(response);
        });
    })    
};


export const registerDelivered = async (sale)=>{   
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/delivered", sale)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const selectPay = async (sale)=>{   
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/paySelect", sale)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const registerPayCuota = async (sale)=>{   
    
    return new Promise((resolve, eject)=>{
      AxiosRequest.post("/api/product/payCuota", sale)
        .then(response => {
            resolve(response);
        })
        .catch(error => {
            resolve(error.response);
        });
    });

}

export const getDeliveredPdf = (itemPay) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/deliveredPdf", itemPay).then( response =>{
            resolve(response);
        });
    })    
};

export const getCreateBuyedPdf = (item) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/buyedPdf", item).then( response =>{
            resolve(response);
        });
    })    
};

export const getCreateBuyedExcel = (item) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/buyedExcel", item).then( response =>{
            resolve(response);
        });
    })    
};

export const getPayPdf = (itemPay) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/payPdf", itemPay).then( response =>{
            resolve(response);
        });
    })    
}

export const getPercentagePdf = (itemPay) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/pil/percentagePil", itemPay).then( response =>{
            resolve(response);
        });
    })    
}

export const getSalesReportPdf = (itemPay) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/salesReportPdf", itemPay).then( response =>{
            resolve(response);
        });
    })    
}

export const getSalesSoyReportPdf = (itemPay) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/salesSoyReportPdf", itemPay).then( response =>{
            resolve(response);
        });
    })    
}

export const getSalesByProductPdf = (itemPay) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/salesProductReportPdf", itemPay).then( response =>{
            resolve(response);
        });
    })    
}

export const getBuyByProductPdf = (itemPay) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/buyProductReportPdf", itemPay).then( response =>{
            resolve(response);
        });
    })    
}

export const getMergeExcel = (itemPay) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/mergeExcel", itemPay).then( response =>{
            resolve(response);
        });
    })    
}

export const getProductsExcel = () => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/productReportExcel").then( response =>{
            resolve(response);
        });
    })    
}

export const getDebtReportPdf = (itemDebt) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/product/salesDebtPdf", itemDebt).then( response =>{
            resolve(response);
        });
    })    
}

export const getAllBrands = () => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly('/api/brand')
        .then( response =>{
            resolve(response);
        });
    })    
  };

  export const getBrand =  id => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getOnly(`/api/brand/${id}`)
        .then( response => {
            resolve(response);
        })
        .catch( error => {
            resolve(error.response);
        });
    });
}